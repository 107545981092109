.bloqueRecuperarContra{
    height: calc(100vh - 70px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0 2em;
}


.bloqueRecuperarContra > div.bloqueSalir{
    width: 100%;
}
.bloqueRecuperarContra > div.bloqueSalir a{
    text-decoration: none;
    background-color: var(--primary2-color);
    height: fit-content;
    width: fit-content;
    color: #fff;
    padding: 0 10.5px 0 10.5px;
    margin-left: 32px;
    font-size: .8em;
    border: none;
    border-radius: 20px;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: start;
    display: flex;
    align-items: center;
    gap: 7.5px;
}
.bloqueRecuperarContra > div.bloqueSalir a::before{
    content: '⇦';
    transform: scale(1.3);
    line-height: 1.5;
}
.bloqueRecuperarContra > form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    width: 79.5%;
}

.bloqueRecuperarContra > img{
    width: 52%;
    max-width: 260px;
}

.bloqueRecuperarContra .formRecuperarContraseña{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    height: 311px;
}
.bloqueRecuperarContra .formRecuperarContraseña form{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    width: fit-content;
    margin: auto;
}
.bloqueRecuperarContra .formRecuperarContraseña form input[type=email]{
    border-radius: 15px;
    padding: 6px 12px;
    border: 1px solid var(--second2-color);
    width: 150%;
    outline: none;
    transition: .3s ease border;
}
.bloqueRecuperarContra .formRecuperarContraseña form input[type=email]:focus{
    border: 1px solid black;
}
.bloqueRecuperarContra .formRecuperarContraseña form input::placeholder{
    line-height: .5;
}
.bloqueRecuperarContra .formRecuperarContraseña form input[type=submit]{
    background-color: var(--primary-color);
    color: #fff;
    padding: 5px 25px;
    border:none;
    border-radius: 20px;
    font-size: .6em;
    font-weight: 600;
}

.bloqueRecuperarContra .formRecuperarContraseña .mensajeEnvioSatisfactorio{
    background-image: url('../../BASE/img/5-fondo-color.png');
    background-size: 100%;
    height: 200px;
    display: none;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}
.bloqueRecuperarContra .formRecuperarContraseña .mensajeEnvioSatisfactorio p{
    width: 80%;
    margin: 0;
    font-weight: 600;
    font-size: 1.05em;
    text-align: center;
    color: var(--primary2-color);
}
.bloqueRecuperarContra .formRecuperarContraseña .mensajeEnvioSatisfactorio img{
    width: 16.5%;
    object-fit: contain;
}

.bloqueRecuperarContra .formRecuperarContraseña .mensajeCorreoIncorrecto{
    background-image: url('../../BASE/img/5-fondo-color.png');
    background-size: 100%;
    height: 200px;
    display: none;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}
.bloqueRecuperarContra .formRecuperarContraseña .mensajeCorreoIncorrecto p{
    width: 80%;
    margin: 0;
    font-weight: 600;
    font-size: 1.05em;
    text-align: center;
    color: var(--primary2-color);
}
.bloqueRecuperarContra .formRecuperarContraseña .mensajeCorreoIncorrecto img{
    width: 13%;
    object-fit: contain;
}

/* -------------------------------PAGINA NUEVA CONTRASEÑA--------------------------------------- */
.bloqueNuevaContraseña{
    height: calc(100vh - 70px);
    display: flex;
    align-items: center;
    flex-direction: column;
}

.bloqueNuevaContraseña form {
    height: fit-content;
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.bloqueNuevaContraseña form h1{
    text-align: center;
    color: var(--primary2-color);
}
.bloqueNuevaContraseña form label {
    display: flex;
    flex-direction: column;
    gap: 7.5px;
}
.bloqueNuevaContraseña form label span{
    padding-left: 15px;
    color: var(--primary2-color);
    font-weight: bold;
    font-size: .8em;
}
.bloqueNuevaContraseña form label span.activeError{
    color: red;
}
.bloqueNuevaContraseña form label .inputText{
    padding: 6.5px 15px 3.5px;
    border-radius: 20px;
    border: 1px solid var(--second2-color);
    font-size: 0.8em;
}
.bloqueNuevaContraseña form label .inputText.focus{
    border: 1px solid var(--second-color);
}
.bloqueNuevaContraseña form label .inputText:focus{
    border: 1px solid var(--second-color);
}
.bloqueNuevaContraseña form label .inputText.activeError{
    border: 2px solid red;
}
.bloqueNuevaContraseña form label div{
    position: relative;
    display: flex;
}
.bloqueNuevaContraseña form label div .inputText{
    padding: 6.5px 15px 4.5px;
    border-radius: 20px;
    border: 1px solid var(--second2-color);
    font-size: 0.9em;
    width: 100%;
    transition: .3s ease-in border;
}
.bloqueNuevaContraseña form label div .inputText.password1:focus{
    border: 1px solid black;
}
.bloqueNuevaContraseña form label div .inputText.password2:focus{
    border: 1px solid black;
}
.bloqueNuevaContraseña form label div i{
    position: absolute;
    line-height: .8;
    background-color: transparent;
    border: none;
    padding: 0;
    right: 15px;
    top: 50%;
    transform: translateY(-45%);
}

.bloqueNuevaContraseña .bloqueBtnCambiar{
    display: flex;
}
.bloqueNuevaContraseña .bloqueBtnCambiar input{
    background-color: var(--primary-color);
    color: #fff;
    padding: 6.5px 0px 4.5px;
    width: 50%;
    font-size: 1em;
    border: none;
    border-radius: 20px;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: center;
    margin: 20px auto;
}
