.bloqueTienda{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
}

.swal2-container > div > h2.swal2-title{
    font-size: 1em ;
}

.swal2-actions .swal2-styled.swal2-confirm{
    background-color: var(--primary2-color);
}
.swal2-actions .swal2-styled.swal2-confirm:focus{
    box-shadow: 0 0 0 3px rgb(38 113 48 / 50%);
}
@media screen and (max-width: 280px){
    .sectionListadoProductos article button.goDetailsProduct p.nameProduct{
        font-size: 0.4em;
    }
}
@media screen and (max-width: 320px){
    .bloqueTienda{
        font-size: 12px;
    }
    header > .headerBottom > a{
        font-size: 0.5em;
    }
    header > .headerBottom .bloqueInputSearch input{
        padding: 3px 15px;
    }

    .sectionListadoProductos article button.goDetailsProduct p.nameProduct{
        font-size: 0.5em;
    }
}
@media screen and (max-width: 375px){
    .bloquePadreListadoProductos{
        padding: 0 10px 20px;
    }
}
@media screen and (min-width: 500px){
    .bloqueTienda{
        font-size: 20px;
    } 
    header > .headerBottom > a{
        font-size: 0.6em;
    }
    header > .headerBottom .bloqueInputSearch input{
        padding: 3px 17px;
    }

    .sectionListadoProductos article button.goDetailsProduct p.nameProduct{
        font-size: 0.6em;
    }
}
@media screen and (min-width: 767px){
    .bloqueTienda {
        font-size: 30px;
    }
    header > .headerBottom input{
        max-width: 280px;
        padding: 8px 10px;
    }
    header > .headerBottom input::placeholder {
        font-size: 1em;
    }
    header > .headerBottom > a::before{
        width: 13px;
        transform: scale(0.13);
    }

    .bloqueIngresarUbicacion > .bloqueMovilUbi, .bloqueIngresarUbicacion > .bloqueTiendaUbi{
        padding: 30px;
        gap: 40px;
    }
    .bloqueIngresaDireccion input{
        padding: 8px 10px;
    }    
    .bloqueIngresaDireccion input::placeholder {
        font-size: 1em;
    }

    .bloqueContinuarCompra button{
        border-radius: 40px;
        max-width: 380px;
        max-height: max-content;
    }

    .bloqueRecuperarContra .mensajeEnvioSatisfactorio{
        padding: 2em 3em;
    }
    .bloqueRecuperarContra .mensajeEnvioSatisfactorio p{
        font-size: 0.6em;
    }
}
@media screen and (min-width: 1024px){
    .bloqueTienda {
        font-size: 25px;
    }

    header > nav{
        padding: 5px 5em;
    }
    header > .headerBottom{
        padding: 1em 5em;
    }
    header > .headerBottom > a{
        width: fit-content;
    }
    .sectionListadoProductos{
        grid-template-columns: repeat(auto-fill, minmax(6em,1fr));
    }
    .bloqueIngresarUbicacion{
        padding: 1em 0;
        margin: auto;
    }
    .bloqueIngresarUbicacion > .bloqueMovilUbi img, .bloqueIngresarUbicacion > .bloqueTiendaUbi img{
        width: 8%;
    }
    .bloqueIngresaDireccion{
        padding: 2em 4em;
        width: 100%;
        margin: auto;
    }
    .bloqueIngresaDireccion input {
        border-radius: 25px;
        padding: 8px 20px;
    }

    .bloqueContinuarCompra button {
        max-width: 300px;
    }
    .bloqueContinuarCompra button.btnContinuarCompra::before{
        font-size: 0.8em;
    }
    .bloqueContinuarCompra button.btnContinuarCompra::after{
        font-size: 1.2em;
    }

    .bloqueCantProductos{
        justify-content: center;
        gap: 5em;
    }

    .bloqueTuCarrito{
        width: 70%;
        margin: auto;
    }

    .bloqueLogin{
        font-size: 18px;
    }
    .bloqueLogin form{
        width: 20em;
    }

    .bloqueRecuperarContra .mensajeEnvioSatisfactorio{
        width: 60%;
    }
}
@media screen and (min-width: 1919px){
    .bloqueTienda {
        font-size: 25px;
    }

    header > nav{
        padding: 5px 12.6em;
    }
    header > .headerBottom{
        width: 80%;
        margin: auto;
    }

    .sectionListadoProductos {
        width: 70%;
        margin: auto;
    }

    .bloqueUbicacionPedido {
        height: calc(100vh - 50%);
    }
    .bloqueIngresarUbicacion,
    .bloqueIngresaDireccion {
        width: 50%;
    }

    .bloqueDetalleProducto, .bloqueViasDeCompra{
        width: 30%;
    }
}