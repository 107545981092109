.bloqueDetalleProducto {
  display: flex;
  flex-direction: column;
  padding: 2em 2em 0;
}
.bloqueDetalleProducto span img{
  padding: 0 1em;
  width: 100%;
}
.bloqueDetalleProducto .bloqueNombreProducto {
  padding: 19px 0;
}
.bloqueDetalleProducto .bloqueNombreProducto p {
  font-size: 1.1em;
  font-weight: bold;
  color: var(--primary-color);
  text-align: center;
  margin: 0;
}
.bloqueDetalleProducto .bloqueDescProducto p {
  font-size: 0.65em;
  text-align: justify;
  color: var(--second-color);
  margin: 0;
  line-height: 2;
  font-weight: 500;
  white-space: pre-wrap;
}
.bloqueDetalleProducto .bloqueCostoProducto {
  margin: 1.88em 0;
  display: flex;
  justify-content: space-between;
}
.bloqueDetalleProducto .bloqueCostoProducto > p {
  font-size: 1.2em;
  font-weight: bold;
  color: var(--primary2-color);
  margin: 0;
  padding: 0 12px;
}
.bloqueDetalleProducto .bloqueCostoProducto .bloqueDescuento {
  /* display: flex; */
  display: none;
  align-items: center;
}
.bloqueDetalleProducto .bloqueCostoProducto .bloqueDescuento .bloqueDescuento__porcentanje{
  background-color: var(--primary2-color);
  border-radius: 15px;
  font-size: .8em;
  color: #fff;
  font-weight: 600;
  padding: 3px 10px;
}
.bloqueDetalleProducto .bloqueCostoProducto .bloqueDescuento p.bloqueDescuento__texto{
  font-size: .6em;
  font-weight: 500;
  margin: 0;
  color: var(--second-color);
  padding: 5px;
}

.bloqueViasDeCompra {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  padding: 0 3em 24px;
  gap: 25px;
}
.bloqueViasDeCompra .bloquesContactarParaComprar {
  grid-column: 1 / -1;
}
.bloqueViasDeCompra .bloqueViaCompraCallCenter,
.bloqueViasDeCompra a .bloqueViaWsp {
  display: flex;
  gap: 7.5px;
  width: min-content;
}
.bloqueViasDeCompra a {
  text-decoration: none;
  display: flex;
  border: 2px solid var(--primary2-color);
  padding: 6.5px 11px 4.5px;
  border-radius: 21.5px;
}
.bloqueViasDeCompra .bloqueViaCompraCallCenter img,
.bloqueViasDeCompra a .bloqueViaWsp img {
  width: 18px;
  object-fit: contain;
}
.bloqueViasDeCompra .bloqueViaCompraCallCenter .bloqueInfoCallCenter,
.bloqueViasDeCompra a .bloqueViaWsp .bloqueInfoWsp {
  font-size: .6em;
  color: var(--second-color);
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: center;
  width: max-content;
}
.bloqueViasDeCompra .bloqueViaCompraCallCenter .bloqueInfoCallCenter p,
.bloqueViasDeCompra a .bloqueViaWsp .bloqueInfoWsp p {
  margin: 0;
  width: max-content;
  text-overflow: initial;
  line-height: 1;
}
.bloqueViasDeCompra a .bloqueViaCompraCallCenter .bloqueInfoCallCenter span,
.bloqueViasDeCompra a .bloqueViaWsp .bloqueInfoWsp span {
  line-height: 1;
}
.bloqueViasDeCompra .wrapper {
  height: 2.2em;
  min-width: 8em;
  width: 100%;
  max-width: 165px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-color);
  border-radius: 30px;
  color: var(--primary-color);
}
.bloqueViasDeCompra .wrapper span {
  width: 100%;
  text-align: center;
  font-size: 2em;
  font-weight: 800;
  cursor: pointer;
  user-select: none;  
  color: white;
}
.bloqueViasDeCompra .wrapper span.num {
  font-size: 1.4em;
  pointer-events: none;
}
.bloqueViasDeCompra .btnWrapperAgregar {
  background-color: var(--primary2-color);
  color: white;
  border-radius: 25px;
  font-size: 0.7em;
  font-weight: 700;
  border: none;
  width: fit-content;
  padding: 0 1.6em;
}

.bloqueCantProductos {
  background-image: url('../../BASE/img/4-barra-cantidad-compra.png');
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 .5em;
  margin-bottom: 14px;
}
.bloqueCantProductos .bloqueEnlace {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  padding: 10px 5px;
}
.bloqueCantProductos .bloqueEnlace a {
  text-decoration: none;
  margin: auto;
}
.bloqueCantProductos .bloqueEnlace button {
  font-family: Montserrat;
  color: #fff;
  font-weight: 700;
  background-color: var(--primary2-color);
  border-radius: 25px;
  border: none;
  line-height: 1;
}
.bloqueCantProductos .bloqueEnlace button.btnAgregarProducts {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 12em;
  padding: 5.5px 29.5px 5px;
  font-size: 0.5em;
  font-weight: 900;
}
.bloqueCantProductos .bloqueEnlace button.btnContinuarCompra {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 22.7em;
  max-height: 12em;
  padding: 5.5px 20px;
}
.bloqueCantProductos .bloqueEnlace button.btnContinuarCompra::before {
  content: 'CONTINUAR COMPRA';
  font-size: 0.7em;
  font-family: Montserrat;
  text-align: start;
  font-weight: 900;
  line-height: 1.2;
  width: 50%;
}
.bloqueCantProductos .bloqueEnlace button.btnContinuarCompra::after {
  content: 's/34.00';
  font-size: 1.3em;
  font-family: Montserrat;
  font-weight: 800;
}

@media (max-width: 375px) {
  .bloqueViasDeCompra a{
    padding: 5.5px 7.5px;
  }
  .bloqueViasDeCompra .bloqueViaCompraCallCenter, .bloqueViasDeCompra a .bloqueViaWsp{
    gap: 2.5px;
  }
  .bloqueViasDeCompra .bloqueViaCompraCallCenter img, .bloqueViasDeCompra a .bloqueViaWsp img{
    width: 14px;
  }
  .bloqueViasDeCompra{
    gap: 1.88em;
  }
  .bloqueCantProductos .bloqueEnlace button.btnContinuarCompra::before{
    font-size: 0.5em;
  }
  .bloqueCantProductos .bloqueEnlace button.btnContinuarCompra::after{
    font-size: 1em;
  }
  .bloqueCantProductos .bloqueEnlace button.btnContinuarCompra{
    padding: 7.5px 15px;
  }
}

@media (min-width: 768px) {
  .bloqueDetalleProducto{
    flex-direction: row;
  }
  .bloqueDetalleProducto > img{
    width: 40%;
  }
  .bloqueDetalleProducto > div{
    padding: 0 2em 0 0;
  }
}

@media (min-width: 912px) and (min-height: 1368px) {
  .bloqueGeneralDetalleProducto{
    height: calc(100vh - 548px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .bloqueDetalleProducto{
    padding: 25px 2em 25px;
  }
}



@media (min-width: 1024px) {
  
  .bloqueDetalleProducto{
    width: 80%;
    margin: auto;
  }  
  .bloqueDetalleProducto > img{
    width: 100%;
  }
  .bloqueDetalleProducto > div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .bloqueViasDeCompra{
    width: 70%;
    margin: auto;
  }
}

@media (min-width: 1366px) {
  .bloqueDetalleProducto .bloqueDescProducto p{
    line-height: 2;
  }
  
  .bloqueViasDeCompra{
    width: 60%;
  }
}