.bloquePadreListadoProductos{
  padding: 0px 27px 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2.5em;
  min-height: calc(100vh - 302.66px);
  max-height: 100%;
}
.bloquePadreListadoProductos .bloqueNombreCategoria{
  display: flex;
  flex-direction: column;
  margin-bottom: 11px;
}
.bloquePadreListadoProductos .bloqueNombreCategoria p{
  text-align: center;
  font-size: 10pt;
  color: var(--primary-color);
  font-weight: bold;
  margin: 0;
  line-height: 1;
}
.bloquePadreListadoProductos .bloqueNombreCategoria hr{
  background-color: var(--primary-color);
  height: 2.6px;
  width: 50px;
  margin: 4px auto 11px;
}

.sectionListadoProductos{
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: -10px;
}
.sectionListadoProductos .bloqueSwiperButtons{
  display: none;
  gap: 10px;
}
.sectionListadoProductos .swiper{
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
}
.sectionListadoProductos .swiper-button-prev,
.sectionListadoProductos .swiper-button-next {
  cursor : url(https://prueba3.mundosantanatura.com/imagenesPrueba/manitoPuntero.svg), pointer;
}

.sectionListadoProductos article {
  display: flex;
  flex-direction: column;
}
.sectionListadoProductos article button.goDetailsProduct {
  background: none;
  border: none;
}
.sectionListadoProductos article button.goDetailsProduct p.nameProduct {
  font-size: 0.5em;
  color: #8aab04;
  font-weight: 500;
  text-align: center;
  margin: 13px 3px;
  height: 15px;
  line-height: 1.1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sectionListadoProductos article button.goDetailsProduct div.bloqueImgProduct {
  padding: 10px 0;
  transition: 0.3s ease background-color;
  height: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sectionListadoProductos article button.goDetailsProduct:hover div.bloqueImgProduct {
  background-color: var(--primary-color);
}
.sectionListadoProductos article button.goDetailsProduct div img {
  width: 75px;
}
.sectionListadoProductos article p.priceProduct {
  font-size: 0.66em;
  color: #267130;
  font-weight: 800;
  text-align: center;
  margin: 0px auto 5px;
}
.sectionListadoProductos article div.bloqueBtnAñadirCarrito {
  display: flex;
  justify-content: center;
}
.sectionListadoProductos article div.bloqueBtnAñadirCarrito button {
  background-color: var(--primary-color);
  border-radius: 16px;
  color: white;
  margin: auto;
  border: none;
  padding: 5px 7.5px;
  font-size: 0.5em;
  font-weight: 800;
  transition: 0.3s ease background-color;
}
.sectionListadoProductos article:hover div.bloqueBtnAñadirCarrito button {
  background-color: var(--primary2-color);
}

/* ---------------------------------------------------- */
.alert {
  display: none;
  background: #ffdb9b;
  height: fit-content;
  padding: 10px;
  min-width: 160px;
  position: sticky;
  left: 52%;
  top: -30px;
  border-radius: 4px;
  border-left: 8px solid #ffa502;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  transition: .3s ease opacity;
}
.alert.showAlert {
  display: block;
  opacity: 1;
  pointer-events: auto;
}
.alert.show {
  animation: show_slide 1s ease forwards;
}
@keyframes show_slide {
  /* 0% {
    transform: translateX(0%);
  }
  40% {
    transform: translateX(-10%);
  }
  80% {
    transform: translateX(0%);
  } */
  100% {
    transform: translateX(-10px);
  }
}
.alert.hide {
  animation: hide_slide 1s ease forwards;
}
@keyframes hide_slide {
  0% {
    transform: translateX(-10px);
  }
  40% {
    transform: translateX(0%);
  }
  80% {
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(100%);
  }
}
.alert .fa-exclamation-circle {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: #ce8500;
  font-size: 30px;
}
.alert .msg {
  padding: 0 20px;
  font-size: 18px;
  color: #ce8500;
}
.alert .close-btn {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  background: #ffd080;
  padding: 20px 18px;
  cursor: pointer;
}
.alert .close-btn:hover {
  background: #ffc766;
}
.alert .close-btn .fas {
  color: #ce8500;
  font-size: 22px;
  line-height: 40px;
}


/* @media (min-width: 1366px){
  .alert {
    display: block;
  }
} */

.swiper-pagination-bullet{
  background-color: #6E93f7;
  opacity: 1;
}
.swiper-pagination-bullet-active{
  background-color: #4070F4;
}

@media (min-width: 768px) {
  .bloqueSwiperButtons{
    display: flex;
  }
}