.bloqueUbicacionPedido {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.bloqueIngresarUbicacion {
  display: flex;
  flex-direction: column;
  padding: 0 0 15px;
}
.bloqueIngresarUbicacion > div:nth-child(1) {
  background-color: var(--second2-color);
  border-radius: 15px 15px 0 0;
  border: 1px solid var(--second2-color);
  padding: 10px 20px;
}
.bloqueIngresarUbicacion > div:nth-child(1) > h2 {
  color: #fff;
  margin: 0;
  font-size: 0.8em;
}
.bloqueIngresarUbicacion > .bloqueMovilUbi,
.bloqueIngresarUbicacion > .bloqueTiendaUbi {
  border: 1px solid var(--second2-color);
  display: flex;
  padding: 12px 20px;
  gap: 30px;
  align-items: center;
  transition: background-color 0.3s ease;
}
.bloqueIngresarUbicacion > .bloqueMovilUbi {
  border-bottom: 0;
}
.bloqueIngresarUbicacion > .bloqueTiendaUbi {
  border-top: 0;
}
.bloqueIngresarUbicacion > .bloqueMovilUbi,
.bloqueIngresarUbicacion > .bloqueTiendaUbi{
    cursor: pointer;
}

.bloqueIngresarUbicacion > .bloqueMovilUbi.selected,
.bloqueIngresarUbicacion > .bloqueTiendaUbi.selected {
  background-color: var(--primary-color);
}
.bloqueIngresarUbicacion > .bloqueMovilUbi img,
.bloqueIngresarUbicacion > .bloqueTiendaUbi img {
  width: 15%;
  object-fit: contain;
}
.bloqueIngresarUbicacion > .bloqueMovilUbi p,
.bloqueIngresarUbicacion > .bloqueTiendaUbi {
  font-size: 0.8em;
  color: var(--second-color);
  line-height: 1.4;
  margin: 0;
  transition: color 0.3s ease;
}
.bloqueIngresarUbicacion > .bloqueMovilUbi.selected p,
.bloqueIngresarUbicacion > .bloqueTiendaUbi.selected {
  color: #fff;
}
.bloqueIngresarUbicacion > .bloqueTiendaUbi {
  border: 1px solid var(--second2-color);
  border-radius: 0 0 15px 15px;
}
.bloqueIngresarUbicacion > .bloqueTiendaUbi > div {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.bloqueIngresarUbicacion > .bloqueTiendaUbi > div p {
  margin: 0;
}

.bloqueIngresaDireccion {
  display: flex;
  justify-content: center;
  padding: 5px 2em 15px;
  position: relative;
}
.bloqueIngresaDireccion .bloqueSelectsDepProvDis{
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.bloqueIngresaDireccion .bloqueSelectsDepProvDis div{
  display: flex;
  flex-direction: column;
  flex: 0 1 137px;
}
.bloqueIngresaDireccion .bloqueSelectsDepProvDis div p{
  font-size: .8em;
  margin-top: .25em;
  margin-bottom: .5em;
  color: var(--second-color);
}
.bloqueIngresaDireccion .bloqueSelectsDepProvDis div select{
  border: 1px solid var(--second-color);
  border-radius: 20px;
  outline: none;
  padding: 5px;
  position: relative;
  font-size: .7em;
  color: var(--second-color);
  width: 100%;
  text-overflow: ellipsis
}
.bloqueIngresaDireccion .bloqueSelectsDepProvDis div select#valueSelectDistrito{
  width: auto;
}
select:not(:-internal-list-box){
  height: 100px !important;
}
.bloqueIngresaDireccion input {
  border-radius: 15px;
  border: 1px solid var(--second2-color);
  padding: 5px 21px 2.5px;
  width: 100%;
  background-image: url(../../BASE/img/elementos/imgIconAddress.png);
  background-repeat: no-repeat;
  background-size: 11px;
  background-position: 7px center;
  opacity: .7;
  margin-top: 20px;
  transition: .3s ease border;
  font-size: 0.8em;
  height: 25px;
  text-overflow: ellipsis;
}
.bloqueIngresaDireccion input.focus {
  border: 1px solid var(--second-color);
}
.bloqueIngresaDireccion input:focus {
  border: 1px solid var(--second-color);
}
.bloqueIngresaDireccion input::placeholder {
  font-size: 0.8em;
  font-style: italic;
}
.bloqueIngresaDireccion .autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  top: 100%;
  left: 2em;
  right: 0;
  width: fit-content;
  max-height: 240px;
  overflow-y: auto;
}
.bloqueIngresaDireccion .autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
}
.bloqueIngresaDireccion .autocomplete-items div:hover {
  /*when hovering an item:*/
  background-color: #e9e9e9;
}
.bloqueIngresaDireccion .autocomplete-active {
  /*when navigating through the items using the arrow keys:*/
  background-color: DodgerBlue !important;
  color: #ffffff;
}

.bloqueContinuarCompra {
  background-image: url('../../BASE/img/4-barra-cantidad-compra.png');
  display: flex;
  justify-content: center;
  padding: 15px 0;
}
.bloqueContinuarCompra a {
  text-decoration: none;
}
.bloqueContinuarCompra a button {
  font-family: Montserrat;
  color: #fff;
  font-size: 0.8em;
  font-weight: 700;
  background-color: var(--primary2-color);
  padding: 8px 20px;
  border-radius: 20px;
  border: none;
  max-width: 250px;
  max-height: 40px;
}
.bloqueContinuarCompra a button.btnContinuarCompra {
  display: flex;
  justify-content: space-between;
  gap: 0;
  align-items: center;
}
.bloqueContinuarCompra a button.btnContinuarCompra::before {
  content: 'CONTINUAR COMPRA';
  font-family: Montserrat;
  text-align: start;
  font-weight: 600;
  font-size: 1em;
}
.bloqueContinuarCompra a button.btnContinuarCompra::after {
  content: 's/34.00';
  font-size: 1.6em;
  font-family: Montserrat;
}



.bloqueUbicacionPedido .bloqueLocalSeleccionado{
  margin: 5px 0 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0 10px;
}
.bloqueUbicacionPedido .bloqueLocalSeleccionado label{
  color: var(--primary2-color);
  font-weight: 600;
  font-size: .7em;
  padding: 0 15px;
}
.bloqueUbicacionPedido .bloqueLocalSeleccionado input{
  color: #fff;
  background-color: var(--primary-color);
  border-radius: 15px;
  padding: 8.5px 25px;
  border: none;
  width: 100%;
  font-style: italic;
  font-size: .7em;
}

@media screen and (min-height: 666px) {
  /* .bloqueUbicacionPedido {
    height: calc(100vh - 325px);
  } */
}
@media screen and (min-height: 739px) and (max-height: 811px) {
  .bloqueUbicacionPedido {
    height: auto;
  }
}

@media screen and (max-width: 375px) {
  .bloqueIngresaDireccion{
    padding: 5px 0.8em 15px;
  }
}
@media screen and (max-width: 280px) {
  /* .bloqueUbicacionPedido {
    height: calc(100vh - 460px);
  } */

  .bloqueImgFooter::before {
    font-size: 1.1em;
  }
}
@media (min-width: 768px) {
  .bloqueContinuarCompra a button {
    padding: 40px 20px;
    border-radius: 40px;
    max-width: 340px;
  }
  .bloqueContinuarCompra a button.btnContinuarCompra::before{
    font-size: 8pt;
  }
  .bloqueContinuarCompra a button.btnContinuarCompra::after{
    font-size: 1.4em;
  }
}

/* @media screen and (min-height: 900){
    .bloqueUbicacionPedido {
        height: calc(100vw + 17.4vh);
    }
  } */
