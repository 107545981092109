.bloqueInfoListaProductos{
    display: flex;
    justify-content: space-between;
    border-radius: 15px;
}
.bloqueInfoListaProductos:nth-last-child(1){
    margin-bottom: 20px;
}
.bloqueInfoListaProductos .bloqueImgProducto{
    background-color: var(--primary-color);
    border-radius: 15px 0 0 15px;
}
.bloqueInfoListaProductos .bloqueImgProducto{
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}
.bloqueInfoListaProductos .bloqueImgProducto img{
    width: 100%;
}

.bloqueInfoListaProductos .bloqueInfoCantidadProducto{
    min-width: 200px;
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px 10px;
    gap: 13px;
    border: 1px solid rgb(181, 179, 179);
    border-radius: 0 15px 15px 0;
}
.bloqueInfoListaProductos .bloqueInfoCantidadProducto .bloqueInfoEliminar{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.bloqueInfoListaProductos .bloqueInfoCantidadProducto .bloqueInfoEliminar > div{
    display: flex;
    flex-direction: column;
    gap: 4px;
    color: var(--primary2-color);
}
.bloqueInfoListaProductos .bloqueInfoCantidadProducto .bloqueInfoEliminar > div p{
    margin: 0;
    font-size: 0.7em;
    font-weight: 600;
    padding-right: 10px;
}
.bloqueInfoListaProductos .bloqueInfoCantidadProducto .bloqueInfoEliminar > img{
    width: 6%;
    object-fit: contain;
    cursor: pointer;
}
.bloqueInfoListaProductos .bloqueInfoCantidadProducto .bloqueWrapperCantProductos .wrapper{
    height: 1.6em;
    min-width: 6em;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFF;
    border-radius: 2em;
    color: var(--primary-color);
    width: min-content;
    border: 1px solid var(--second2-color);
}
.bloqueInfoListaProductos .bloqueInfoCantidadProducto .bloqueWrapperCantProductos .wrapper span{
    width: 100%;
    text-align: center;
    font-size: 1.4em;
    font-weight: 800;
    cursor: pointer;
    user-select: none;
}
.bloqueInfoListaProductos .bloqueInfoCantidadProducto .bloqueWrapperCantProductos .wrapper span.num{
    font-size: 1em;
    pointer-events: none;
}

@media (min-width: 768px) {
    .bloqueInfoListaProductos .bloqueImgProducto{
        width: 20%;
    }
    .bloqueInfoListaProductos .bloqueInfoCantidadProducto{
        min-width: 230px;
    }
}