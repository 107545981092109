.bloqueRegistrarse{
    height: calc(100vh - 70px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.bloqueRegistrarse > img.imgBarraPortada{
    width: 100%;
}
.bloqueRegistrarse > img.imgLogoSN{
    width: 52%;
    max-width: 260px;
}
.bloqueRegistrarse > div.bloqueSalir {
    width: 100%;
    height: 62px;
    display: flex;
    justify-content: flex-start;
}
.bloqueRegistrarse > div.bloqueSalir a{
    text-decoration: none;
    background-color: var(--primary2-color);
    height: fit-content;
    color: #fff;
    padding: 0 10.5px 0 10.5px;
    margin-left: 32px;
    margin-top: 10px;
    font-size: .8em;
    border: none;
    border-radius: 20px;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: center;
    display: flex;
    align-items: center;
    gap: 7.5px;
}
.bloqueRegistrarse > div.bloqueSalir a::before{
    content: '⇦';
    transform: scale(1.3);
    line-height: 1.5;
}
.bloqueRegistrarse > form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* gap: calc(100vh - 733px); */
    gap: 10px;
    width: 79.5%;
}

.bloqueRegistrarse > form h1{
    color: var(--primary2-color);
    font-size: 1.8em;
    text-align: center;
}
.bloqueRegistrarse > form label {
    display: flex;
    flex-direction: column;
    gap: 7.5px;
}
.bloqueRegistrarse > form label >span{
    padding-left: 15px;
    color: var(--primary2-color);
    font-weight: bold;
    font-size: .8em;
}
.bloqueRegistrarse > form label > span.activeError{
    color: red;
}
.bloqueRegistrarse > form label > .inputText{
    padding: 0 15px;
    border-radius: 20px;
    border: 1px solid var(--second2-color);
    font-size: 0.8em;
    height: 28px;
    vertical-align: middle;
    transition: .3s ease border;
}
.bloqueRegistrarse > form label > .inputText.focus{
    border: 1px solid var(--second-color);
}
.bloqueRegistrarse > form label > .inputText:focus{
    border: 1px solid var(--second-color);
}
.bloqueRegistrarse > form label > .inputText.activeError{
    border: 2px solid red;
}
.bloqueRegistrarse > form label > div{
    position: relative;
    display: flex;
}
.bloqueRegistrarse > form label > div .inputText{
    padding: 6.5px 15px 4.5px;
    border-radius: 20px;
    border: 1px solid var(--second2-color);
    font-size: 0.9em;
    width: 100%;
    transition: .3s ease border;
}
.bloqueRegistrarse > form label > div .inputText.focus{
    border: 1px solid var(--second-color);
}
.bloqueRegistrarse > form label > div .inputText:focus{
    border: 1px solid var(--second-color);
}
.bloqueRegistrarse > form label div i{
    position: absolute;
    line-height: .8;
    background-color: transparent;
    border: none;
    padding: 0;
    right: 15px;
    top: 50%;
    transform: translateY(-45%);
}
/* .bloqueRegistrarse > form .inputText::placeholder{
    color: var(--primary2-color);
    font-weight: 700;
    font-size: 1em;
} */
.bloqueRegistrarse > form p{
    font-size: .8em;
    color: var(--second-color);
    text-align: center;
    padding: 0 4px;
    margin-bottom: 20px;
    margin-top: 25px;
    line-height: 1.4;
}
.bloqueRegistrarse > form p span{
    color: var(--primary-color);
}
.bloqueRegistrarse > form .bloqueButtons {
    display: flex;
}
/* .bloqueRegistrarse > form .bloqueButtons a{
    text-decoration: none;
    background-color: var(--second-color);
    color: #fff;
    padding: 6.5px 20px 4.5px;
    width: 45%;
    font-size: 1em;
    border: none;
    border-radius: 20px;
    font-weight: 600;
    margin: 0 auto auto;
    letter-spacing: 1px;
    text-align: center;
} */
.bloqueRegistrarse > form .spanError{
    color: #dc3545;
    font-weight: normal;
    font-size: .8rem;
    line-height: 1.6;
    position: absolute;
    opacity: 0;
}
.bloqueRegistrarse > form .spanError.active{
    position: relative;
    opacity: 1;
}
.bloqueRegistrarse > form .bloqueButtons input[type=submit]{
    background-color: var(--primary-color);
    color: #fff;
    padding: 6.5px 0px 4.5px;
    width: 89.1%;
    font-size: 1em;
    border: none;
    border-radius: 20px;
    font-weight: 600;
    margin: 0 auto auto;
    letter-spacing: 1px;
    text-align: center;
}

.bloqueRegistrarse .mensajeEnvioSatisfactorio{
    background-image: url('../../BASE/img/5-fondo-color.png');
    background-size: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.bloqueRegistrarse .mensajeEnvioSatisfactorio p{
    width: 90%;
    margin: 0;
    font-weight: 700;
    font-size: 1.7em;
    text-align: center;
    color: var(--primary2-color);
}
.bloqueRegistrarse .mensajeEnvioSatisfactorio img{
    width: 30%;
    margin-bottom: 20px;
}
.swal2-container.swal2-center>.swal2-popup {
    font-size: 0.6rem;
}
/*---------------------FINALIZAR REGISTRO-----------------*/
.bloqueFinalizarRegistro{
    width: 100vw;
}
.bloqueFinalizarRegistro .holaIDsantanarutin{
    width: 100%;
    background-image: url(../../BASE/img/elementos/holaIDsantanarutin.png);
    background-size: 100%;
    background-repeat: no-repeat;
    margin-top: 15px;
    height: 226px;
    position: relative;
}
.bloqueFinalizarRegistro .holaIDsantanarutin p{
    position: absolute;
    color: var(--primary2-color);
    font-size: 1.8em;
    font-weight: bold;
    margin: 0;
    top: 50%;
    left: 12%;
    width: 5em;
    text-align: center;
}
.bloqueFinalizarRegistro form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    width: 75%;
    margin: 40px auto 0;
}
.bloqueFinalizarRegistro form label {
    display: flex;
    flex-direction: column;
    gap: 7.5px;
}
.bloqueFinalizarRegistro form label .inputText{
    padding: 6.5px 15px 3.5px;
    border-radius: 20px;
    border: 1px solid var(--second2-color);
    font-size: 0.8em;
}
.bloqueFinalizarRegistro form label .inputText:focus{
    border: 1px solid var(--second-color);
}
.bloqueFinalizarRegistro form label .inputText.focus{
    border: 1px solid var(--second-color);
}
.bloqueFinalizarRegistro form label .inputText::placeholder{
    color: var(--primary2-color);
    font-weight: 700;
    font-size: 1em;
}
.bloqueFinalizarRegistro form p{
    font-size: .7em;
    color: var(--second-color);
    text-align: center;
    padding: 0 4px;
    margin-bottom: 15px;
    margin-top: 10px;
    line-height: 1.4;
}
.bloqueFinalizarRegistro form input[type=submit]{
    background-color: var(--primary-color);
    color: #fff;
    padding: 4.5px 20.8px 4.5px;
    font-size: 0.7em;
    border: none;
    border-radius: 20px;
    font-weight: 600;
    margin: 0 auto auto;
    letter-spacing: 1px;
    text-align: center;
}

.bloqueFinalizarRegistro img.imgRegistroFinalizado{
    width: 100vw;
    height: calc(100vh - 103px);
    position: absolute;
    top: 33px;
    z-index: 9;
}



@media (min-height: 812px){
    .bloqueRegistrarse > form{
        gap: calc(100vh - (100vh - 20px));
    }
}

@media (max-width: 280px){
    .bloqueRegistrarse > form{
        gap: calc(100vh - 643px);
    }
}

@media (min-width: 539px){
    .bloqueRegistrarse > img{
        width: 50%;
    }
    .bloqueRegistrarse > form{
        width: 50%;
    }
    .bloqueRegistrarse > form p{
        font-size: 0.6em;
    }
}

@media (min-width: 768px){
    .bloqueRegistrarse > form .inputText{
        padding: 7px 15px 4px;
        border-radius: 30px;
    }
    .bloqueRegistrarse > form{
        gap: 15px;
    }
    .bloqueRegistrarse > form input[type=submit]{
        padding: 8px 40px;
        font-size: 0.6em;
    }
    .bloqueRegistrarse .mensajeEnvioSatisfactorio{
        height: fit-content;
        padding: 20px 40px;
    }
    .bloqueRegistrarse .mensajeEnvioSatisfactorio p{
        width: 100%;
        font-size: 1.4em;
    }
    .bloqueRegistrarse .mensajeEnvioSatisfactorio img{
        width: 6em;
    }
}

@media (min-width: 1024px){
    .bloqueRegistrarse > img {
        width: 25%;
    }
    .bloqueRegistrarse > form{
        gap: 8.5px;
        width: 30%;
    }
    .bloqueRegistrarse > form .inputText{
        font-size: 0.6em;
    }
}