.bloqueLogin{
    background-image: url('../../BASE/img/2-FONDO-movil.png');
    height: 100vh;
    background-size: cover;
    /* background-color: radial-gradient(var(--primary2-color), #C4D2C2); */
    background-repeat: no-repeat;
    background-position: center;

}


.bloqueLogin form{
    padding-top: calc(100vh - 20em);
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100vh;
    position: relative;
    width: 20em;
    margin: auto;
}


.bloqueLogin form .bloqueInput{
    position: relative;
}
.bloqueLogin form .bloqueInput label{
    display: flex;
    flex-direction: column;
    color: var(--primary2-color);
    padding-left: 10px;
    font-weight: 700;
    font-size: 0.8em;
    gap: 5px;
    margin: 0 0 7.5px 0;
}
.bloqueLogin form .bloqueInput input{
    padding: 7px 20px 4.5px;
    border-radius: 20px;
    border: 0px solid;
    outline: none;
    width: 100%;
}
.bloqueLogin form .bloqueInput .divIconEye {
    display: block;
    height: 40px;
    font: 22px sans-serif;
    top: 50%;
    right: 0;
    position: absolute;
}
.bloqueLogin form .bloqueInput .divIconEye>i {
    position: absolute;
    right: 15px;
    bottom: 18px;
    cursor: pointer;
}
.bloqueLogin form .input-spanError {
    position: relative;
    width: 100%;
    margin-top: -15px;
    margin-bottom: -30px;
}
.bloqueLogin form .input-spanError .spanError {
    color: #dc3545;
    font-weight: normal;
    font-size: 0.65rem;
    line-height: 1.6;
    position: absolute;
    opacity: 0;
}
.bloqueLogin form .input-spanError .spanError.active {
    position: relative;
    opacity: 1;
    line-height: 1.3;
    display: flex;
}


.bloqueLogin form .bloqueRecuperarContraseña{
    margin-top: 20px;
    margin-bottom: -20px;
    position: relative;
    z-index: 99;
}
.bloqueLogin form .bloqueRecuperarContraseña a{
    text-decoration: none;
    display: flex;
    justify-content: center;
}
.bloqueLogin form .bloqueRecuperarContraseña a p{
    color: var(--second-color);
    font-size: 0.7em;
    font-weight: 600;
    width: fit-content;
}


.bloqueLogin form .bloqueButtonLogin{
    display: flex;
    justify-content: space-evenly;
    margin-top: 15px;
}
.bloqueLogin form .bloqueButtonLogin input,
.bloqueLogin form .bloqueButtonLogin a{
    color: #fff;
    padding: 7.5px 15px;
    font-size: 0.7em;
    font-weight: 600;
    border-radius: 15px;
    background-color: var(--primary2-color);
    border: none;
    width: 119px;
    text-align: center;
}
.bloqueLogin form .bloqueButtonLogin a{
    text-decoration: none;
}


.bloqueLogin form .bloqueIndicacionIndispensable{
    position: absolute;
    bottom: 0;
    max-width: calc(100vw - 10%);
    margin: auto;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
}
.bloqueLogin form .bloqueIndicacionIndispensable p{
    color: var(--primary2-color);
    font-weight: 500;
    font-size: 0.5em;
    word-spacing: 0.15em;
    padding: 10px;
    text-align: justify;
}
@media(min-width: 500px){
    .bloqueLogin{
        background-size: contain;
    }
}
@media (max-width: 280px){
    .bloqueLogin form{
        width: 16em; 
        padding-top: calc(100vh - 22.5em);
        padding-left: 20px;
        padding-right: 20px;
    }
    .bloqueLogin form .bloqueButtonLogin button, .bloqueLogin form .bloqueButtonLogin a{
        font-size: 0.6em;
    }
}
@media (min-height: 736px) and (max-width: 500px){
    .bloqueLogin form{
        padding-top: calc(100vh - 21.5em);
    }
}
@media (min-height: 844px) and (max-width: 500px){
    .bloqueLogin form{
        padding-top: calc(100vh - 30em);
    }
}