.fondo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-image: url(../../BASE/img/elementos/fondoMedida414X736px.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    animation: ocultarFondo 5s; 
    transition: opacity ease-in;
  }

  .fondo .logoCentro {
    object-fit: contain;
    transition: 0.3s transform, 0.3s ease-out opacity;
    animation: movimientoLogoMedio 3s;
    width: 60%;
  }

  @keyframes movimientoLogoMedio {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    60% {
      transform: scale(1.48);
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes ocultarFondo {
    0% {
      opacity: 1;
    }
    80%{
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @media (min-width: 1024px) {
    .fondo .logoInferior {
      padding-bottom: 2%;
    }
  }

  @media (min-width: 1366px) {
    .fondo .logoCentro {
      width: 15%;
    }
  }