.bloqueImgFooter {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../../BASE/img/elementos/PieDePaginaME_1242x196px.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    height: 70px;
}
.bloqueImgFooter p{
    color: white;
    font-size: 1.1em;
    text-align: center;
    font-weight: 600;
}