.bloqueTuCarrito{
    padding: 16px 1.4em;
}
.bloqueTuCarrito .bloqueTuCarrito__top{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.bloqueTuCarrito .bloqueTuCarrito__top img{
    width: 6%;
    object-fit: contain;
    cursor: pointer;
}
.bloqueTuCarrito .bloqueTuCarrito__top h1{
    margin: 0;
    line-height: .8;
    color: var(--primary2-color);
    font-size: 19pt;
}
.bloqueTuCarrito .bloqueTuCarrito__top button{
    border-radius: 15px;
    color: #fff;
    background-color: var(--primary-color);
    border: none;
    outline: none;
    font-family: Montserrat;
    padding: 3px 8.5px 5px;
    font-size: 0.7em;
    font-weight: bold;
    letter-spacing: 1px;
}

.bloqueTuCarrito__listaProductosAñadidos{
    display: flex;
    flex-direction: column;
    padding: 20px 10px 10px;
}

.bloqueTuCarrito__listaProductosAñadidos .bloqueTextoCantidadProductos p{
    color: var(--second-color);
    font-size: 0.7em;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 5px;
}

.bloqueTuCarrito__listaProductosAñadidos .box{
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr) );
    align-items: center;
    justify-items: center;
    margin-bottom: 20px;
}



.bloqueTuCarrito__listaProductosAñadidos .bloqueTipoDeComprobante{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 10px;
    position: relative;
    margin-top: 10px;
}
.bloqueTuCarrito__listaProductosAñadidos .bloqueTipoDeComprobante .bloqueSelectTipoComprobantes{
    border: 1px solid var(--second-color);
    border-radius: 20px;
    outline: none;
    padding: 1px 10px;
    appearance: none;
    position: relative;
    font-size: .6em;
    color: var(--second-color);
    width: 100%; 
}
.bloqueTuCarrito__listaProductosAñadidos .bloqueTipoDeComprobante .bloqueSelectTipoComprobantes::before{
    content: url('../../BASE/img/elementos/botonPlayVideo_12x12px.png');
    position: absolute;
    right: 24px;
    bottom: 1px;
    z-index: 999;
    width: 6px;
    transform: rotate(90deg) scale(0.4);
}
.bloqueTuCarrito__listaProductosAñadidos .bloqueTipoDeComprobante .bloqueSelectTipoComprobantes select{
    border: none;
    appearance: none;
    width: 90%;
    outline: none;
    padding: 7.5px 0px 7.5px 10px;
    position: relative;
    z-index: 9;
    font-size: 1.4em;
    background-color: transparent;
}
.bloqueTuCarrito__listaProductosAñadidos .bloqueTipoDeComprobante .bloqueIngreseRuc form{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.bloqueTuCarrito__listaProductosAñadidos .bloqueTipoDeComprobante .bloqueIngreseRuc form input{
    border: 1px solid var(--second-color);
    border-radius: 20px;
    outline: none;
    padding: 9px 10px 7px;
    height: fit-content;
    font-size: .6em;
    width: 50%;
    letter-spacing: 1px;
}
.bloqueTuCarrito__listaProductosAñadidos .bloqueTipoDeComprobante .bloqueIngreseRuc form input::placeholder{
    color: var(--second-color);    
    font-weight: 500;
    letter-spacing: 0;
}
.bloqueTuCarrito__listaProductosAñadidos .bloqueTipoDeComprobante .bloqueIngreseRuc form input[type="submit"]{
    background-color: var(--primary2-color);
    color: #fff;
    padding: 6px 14px;
    border-radius: 20px;
    border: none;
    font-size: 0.7em;
    font-weight: 500;
    width: fit-content;
}

.bloqueTuCarrito__listaProductosAñadidos .bloqueComentarios{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
.bloqueTuCarrito__listaProductosAñadidos .bloqueComentarios p{
    color: var(--second-color);
    font-size: 0.75em;
    width: 90%;
    line-height: 1.3;
    margin: 12px 0 6px;
}
.bloqueTuCarrito__listaProductosAñadidos .bloqueComentarios textarea {
    border: 1px solid var(--second-color);
    border-radius: 20px;
    height: 60px;
    resize: none;
    padding: 6px 12px;
    font-size: 0.8em;
    outline: none;
}

.bloqueTuCarrito__listaProductosAñadidos .bloqueTotalConfirmar {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 30px;
}
.bloqueTuCarrito__listaProductosAñadidos .bloqueTotalConfirmar .bloqueSubTotal,
.bloqueTuCarrito__listaProductosAñadidos .bloqueTotalConfirmar .bloqueCostoDeEnvío,
.bloqueTuCarrito__listaProductosAñadidos .bloqueTotalConfirmar .bloqueTotal{
    color: var(--primary2-color);
    font-size: .9em;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 20px;
    letter-spacing: 0.5px;
}
.bloqueTuCarrito__listaProductosAñadidos .bloqueTotalConfirmar .bloqueSubTotal span{
    font-weight: 700;
}
.bloqueTuCarrito__listaProductosAñadidos .bloqueTotalConfirmar .bloqueTotal label{
    font-weight: 800;
    letter-spacing: 0;
}
.bloqueTuCarrito__listaProductosAñadidos .bloqueTotalConfirmar .bloqueTotal span{
    font-weight: 700;
    font-size: 1.5em;
}

.bloqueTuCarrito__listaProductosAñadidos .bloqueTotalConfirmar .btnConfirmar{
    color: #fff;
    background-color: var(--primary2-color);
    border-radius: 20px;
    padding: 10px 45px;
    text-align: center;
    border: none;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin: 23px auto 0;
    font-size: 0.9em;
    width: fit-content;
}

.bloqueTuCarrito__listaProductosAñadidos .mensajeLegal{
    font-size: 0.75em;
    color: var(--second-color);
    margin: 23px auto 0;
}


#modal {
    background: rgba(0, 0, 0, 0.9);
    color: #fff;
    position: fixed;
    /* top: -100vh; */
    left: 0;
    height: 100vh;
    width: 100vw;
    transition: all .5s;
    z-index: 1000;
    opacity: 0;
    display: none;
    align-items: center;
  }
  #modal .bloqueIngresarDireccionFiscal {
    background-color: var(--primary-color);
    color: #fff;
    position: relative;
    height: min-content;
    width: 90%;
    margin: auto;
    padding: 20px 20px 20px 20px;
    border-radius: 20px;
    
  }
  #modal .bloqueIngresarDireccionFiscal p {
    font-weight: lighter;
    margin: 0;
    font-size: 0.9em;
    text-align: justify;
    line-height: 1.4;
  }
  #modal .bloqueIngresarDireccionFiscal p.cabeceraModal {
    font-size: 1em;
    padding: 0 7.5px;
  }
  #modal .bloqueIngresarDireccionFiscal hr {
    height: 2px;
    color: #fff;
    margin: 7.5px 40px 15px 0px;
  }
  #modal .bloqueIngresarDireccionFiscal > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
    gap: 5px;
  }
  #modal .bloqueIngresarDireccionFiscal > div form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    width: 100%;
  }
  #modal .bloqueIngresarDireccionFiscal > div form p {
    text-align: center;
  }
  #modal .bloqueIngresarDireccionFiscal > div form input {
    width: 85%;
    margin: auto;
    padding: 5px 5px 2.5px;
    font-size: .7em;
  }
  #modal .bloqueIngresarDireccionFiscal > div form input[type='submit'] {
    color: #fff;
    border-radius: 15px;
    background-color: var(--primary2-color);
    outline: none;
    border: none;
    padding: 12.5px 20px 10px;
    margin-top: 10px;
    line-height: .8;
    width: fit-content;
    transition: .3s ease background-color;
  }
  #modal .bloqueIngresarDireccionFiscal > div form input[type='submit']:focus {
    background-color: #3e8548;
  }

  #cerrar-modal {
    display: none;
  }
  #cerrar-modal + label {
    position: absolute;
    top: 1em;
    right: 1em;
    z-index: 100;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    border-radius: 50%;
    transition: all .5s;
  }



@media (max-width: 281px){ 
    .bloqueTuCarrito{
        padding: 16px 0.4em;
    }
    .bloqueTienda{
        font-size: 14px;
    }
}
@media (max-width: 320px) {
    .bloqueIngresaDireccion{
        padding: 5px 1.5em 15px;
    }
    .bloqueIngresaDireccion .bloqueSelectsDepProvDis div{
        flex: 0 1 107px;
    }
}

@media (min-width: 768px){ 
    .bloqueTuCarrito__listaProductosAñadidos .box{
        grid-template-columns: repeat(auto-fill, minmax(430px, 1fr) );
    }
    .bloqueTuCarrito__listaProductosAñadidos {
        width: 80%;
        margin: auto;
    }
}

@media (min-width: 912px) and (min-height: 1368px){ 
    .bloqueTuCarrito{
        height: calc(100vh - 548px);
    }
}