.bloqueCompraExitosa{
    height: calc(100vh - 70px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0 0;
}
.bloqueCompraExitosa > img{
    width: 70%;
    max-width: 260px;
}
.bloqueCompraExitosa .sectionContenidoCompraExitosa {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.bloqueCompraExitosa .sectionContenidoCompraExitosa .mensajeCompraExitosa{
    background-image: url('../../BASE/img/5-fondo-color.png');
    background-size: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.bloqueCompraExitosa .sectionContenidoCompraExitosa .mensajeCompraExitosa p{
    width: 90%;
    margin: 0;
    font-weight: 700;
    font-size: 1.7em;
    text-align: center;
    color: var(--primary2-color);
}
.bloqueCompraExitosa .sectionContenidoCompraExitosa .mensajeCompraExitosa img{
    width: 30%;
    margin-bottom: 20px;
}

.bloqueCompraExitosa .sectionContenidoCompraExitosa .bloqueAgradecimiento{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.bloqueCompraExitosa .sectionContenidoCompraExitosa .bloqueAgradecimiento h3{
    color: var(--primary-color);
    margin: 20px 0 5px;
}
.bloqueCompraExitosa .sectionContenidoCompraExitosa .bloqueAgradecimiento p{
    text-align: start;
    width: 64%;
    font-size: 0.7em;
    line-height: 1.4;
    color: var(--second-color);
}
.bloqueCompraExitosa .sectionContenidoCompraExitosa .bloqueAgradecimiento button{
    background-color: var(--primary-color);
    color: #fff;
    padding: 4px 30px;
    border: none;
    border-radius: 20px;
    font-weight: 600;
    width: fit-content;
    margin: 20px auto auto;
    letter-spacing: 1px;
    font-size: 0.7em;
}

@media (min-width: 768px){
    .bloqueCompraExitosa .sectionContenidoCompraExitosa .mensajeCompraExitosa{
        height: fit-content;
        padding: 20px 40px;
    }
    .bloqueCompraExitosa .sectionContenidoCompraExitosa .mensajeCompraExitosa img{
        width: 6em;
    }
}

@media (min-width: 1024px){
    .bloqueCompraExitosa .sectionContenidoCompraExitosa .mensajeCompraExitosa{
        width: 70%;
        margin: 20px auto 0;
    }
    .bloqueCompraExitosa .sectionContenidoCompraExitosa .mensajeCompraExitosa p{
        font-size: 1.4em;
    }
    .bloqueCompraExitosa .sectionContenidoCompraExitosa .mensajeCompraExitosa img{
        width: 4em;
    }
    .bloqueCompraExitosa .sectionContenidoCompraExitosa .bloqueAgradecimiento button{
        margin: 10px auto auto;
    }
    .bloqueCompraExitosa .sectionContenidoCompraExitosa .bloqueAgradecimiento{
        font-size: 19px;
    }
}